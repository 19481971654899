import { useState, useEffect, useContext } from "react";
import { Link, Navigate } from "react-router-dom";
import axios from "axios";

import { Dropdown, } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEllipsisV,
  faEye,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

import { DashboardLayout } from "../../Layout/DashboardLayout";
import moment from 'moment';

import CustomTable from "../../Components/CustomTable";
import { placeholderImage } from "../../Assets/images";

import "./style.css";
import { questionModal, successModal } from "../../Components/CustomModal";
import CustomFilters from "../../Components/CustomFilters";
import BASEURL from "../../Config/global";
import CustomPagination from "../../Components/CustomPagination";
import CustomLoader from "../../Components/CustomLoader";
import { disabledButtonByPermission } from "../../Components/Permissions";
import { PermissionsContext } from "../../Layout/PermissionContext";

const InfoboxManagement = () => {
  const [data, setData] = useState([]);
  const { permissions } = useContext(PermissionsContext)

  const [enteries, setEnteries] = useState(10);
  const [offset, setOffset] = useState(0);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [filterSearchValue, setFilterSearchValue] = useState("");
  const [sort, setSort] = useState("all");
  const [totalCount, setTotalCount] = useState(0);
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    if (currentPage != undefined) {
      setOffset(currentPage);
    }
  }, [currentPage])

  const modulesWisePermission = (condition) => {
    if (!permissions) {
      return 'd-none'
    }
    return disabledButtonByPermission(permissions, condition)
  }
  useEffect(() => {
    document.title = "Relax Scofa | Infobox Management";
  }, []);

  useEffect(() => {
    fetchData();
  }, [enteries, dateTo, offset, filterSearchValue]);

  async function fetchData() {
    try {
      const response = await axios.get(
        `${BASEURL}/api/user/infoboxes?limit=${enteries}&offset=${offset}&start_date=${dateFrom}&end_date=${dateTo}&search=${filterSearchValue}`
      );
      if (response.data.error !== true) {
        // console.log(response);
        setData(response.data?.data);
        setTotalCount(response.data.count);
      } else {
        console.log(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  // useEffect(() => {
  //   if (filterSearchValue.length > 0) {
  //     const filteredData = data.filter((item) => item?.slider_name?.toLowerCase().includes(filterSearchValue.toLowerCase()));
  //     setData(filteredData)
  //   } else {
  //     fetchData();
  //   }
  // }, [filterSearchValue]);

  const deleteArticle = async (id) => {

    questionModal
      .fire({
        title: `Do you want to delete Info Box?`,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteArticleAction(id);
        }
      });



  };

  const deleteArticleAction = async (id) => {
    setLoader(true);

    try {
      const response = await axios.delete(`${BASEURL}/api/user/infoboxes/${id}`);
      if (response.data.error === false) {
        successModal.fire({
          text: "Info Deleted Successfully",
          confirmButtonText: "Continue",
        });
        setData(data.filter((item) => item.id !== id));
        setLoader(false);
        Navigate("/infobox-management");
      } else {
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const couponsHeader = [
    {
      key: "id",
      title: "S.No",
    },
    {
      key: "title",
      title: "Title",
    },
    {
      key: "createDate",
      title: "Created Date",
    },
    {
      key: "modifiedDate",
      title: "Modified Date",
    },
    {
      key: "actions",
      title: "Actions",
    },
  ];

  const sortingValues = [
    {
      value: "all",
      text: "All",
    },
    {
      value: "status",
      text: "Status",
    },
    {
      value: "registered",
      text: "Registered Date",
    },
  ];

  const handleChangeEntries = (no) => {
    setOffset(0)
    setEnteries(no)
  }
  console.log(" currentPage={offset}", offset)
  return (
    <>
      <DashboardLayout>
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-lg-8 mb-2">
              <h2 className="mainTitle">Info Box </h2>
            </div>
            <div className="col-lg-4 text-end mb-2">
              <Link
                to={"/infobox-management/add-infobox"}
                className={`customButton primaryButton`}
              >
                Add Info Box
              </Link>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <CustomFilters
                enteries={enteries}
                totalCount={totalCount}
                entriesFilter={true}
                setEnteries={handleChangeEntries}
                filterSearch={true}
                filterSearchValue={filterSearchValue}
                setFilterSearchValue={setFilterSearchValue}
                // dateFilter={true}
                setDateFrom={setDateFrom}
                setDateTo={setDateTo}
              // isSearchDropdown={couponsHeader}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <CustomTable headers={couponsHeader}>
                <tbody>
                  {data &&
                    data.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.slider_name}</td>
                          <td>{moment(item.created).format('MM-DD-YYYY')}</td>
                          <td>{moment(item.updated).format('MM-DD-YYYY')}</td>

                          <td>
                            <Dropdown className="tableDropdown">
                              <Dropdown.Toggle
                                variant="transparent"
                                className="notButton classicToggle"
                              >
                                <FontAwesomeIcon icon={faEllipsisV} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu
                                align="end"
                                className="tableDropdownMenu"
                              >
                                <Link
                                  to={`/infobox-management/edit-infobox/${item.id}`}
                                  className={`tableAction `}
                                >
                                  <FontAwesomeIcon
                                    icon={faEdit}
                                    className="tableActionIcon"
                                  />
                                  Edit
                                </Link>
                                <Link
                                  to={`/infobox-management/infobox-detail/${item.id}`}
                                  className={`tableAction `}
                                >
                                  <FontAwesomeIcon
                                    icon={faEdit}
                                    className="tableActionIcon"
                                  />
                                  View
                                </Link>
                                <button
                                  className={`tableAction `}
                                  onClick={() => {
                                    deleteArticle(item.id, item.title);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faTimes}
                                    className="tableActionIcon"
                                  />
                                  Delete
                                </button>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </CustomTable>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <CustomPagination
                enteries={enteries}
                totalCount={totalCount}
                setCurrentPage={setOffset}
                currentPage={offset}
              />
            </div>
          </div>
        </div>
      </DashboardLayout>
      {loader && <CustomLoader />}
    </>
  );
};
export default InfoboxManagement;
